import React, { ReactNode } from 'react';
import s from './TableRowTemplate.module.css';

type propsType = {
  tableHeaderTemplateColumns: string;
  isNotHovered?: boolean;
  children?: ReactNode;
};

const TableRowTemplate: React.FC<propsType> = ({ tableHeaderTemplateColumns, 
  children, 
  isNotHovered
}: propsType) => {
  return (
    <div
      className={isNotHovered ? `${s.tableItem}` : `${s.tableItem} ${s.tableItem__hover}`}
      style={{ gridTemplateColumns: tableHeaderTemplateColumns }}
    >
      {children}
    </div>
  );
};

export default TableRowTemplate;
