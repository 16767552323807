import React from 'react';
import { Button } from '@wework/ray2';
import { paginationType } from '../../../types/types';
import IconButton from '@mui/material/IconButton';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

type propsType = {
  changePage: (page: number) => void;
  pagination: paginationType;
};

const ArrowPagination: React.FC<propsType> = (props) => {
  const { changePage, pagination } = props;
  return (
    <>
      <IconButton
        onClick={() => changePage(pagination.page - 1)} edge="end">
        <KeyboardArrowLeft />
      </IconButton>
      <Button
        size="medium"
        theme='fill'
        onClick={() => changePage(pagination.page)}
      >
        {pagination.page}
      </Button>
      <IconButton
        onClick={() => changePage(pagination.page + 1)} edge="end">
        <KeyboardArrowRight />
      </IconButton>
    </>
  );
};

export default ArrowPagination;
