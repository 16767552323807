import { headerTableType, sortKeyEnum } from '../../../types/tableTemplateTypes';
import TableTemplate from '../../templates/TableTemplate/TableTemplate';
import { TableHeaderCeilTemplate } from 
  '../../templates/TableTemplate/TableHeaderCeilTemplate/TableHeaderCeilTemplate';
import InstanceLogsItem from '../../modules/InstanceLogs/InstanceLogsItem';
import InstanceFilter from '../../modules/InstanceLogs/InstanceFilter';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import Pagination from '../../modules/Pagination/Pagination';
import React, { useState } from 'react';
import { paginationType } from '../../../types/types';
import { setPaginationAC } from '../../../store/instanceLogsReducer';
import LogsWebSocket from '../../../api/LogsWebSocket';
import { InstanceLogsFilterTableType } from '../../../types/instanceLogTypes';
import InstanceSubHeader from '../../modules/InstanceLogs/InstanceSubHeader';


const tableHeaderItems: headerTableType[] = [
  {
    id: 1,
    name: 'Reader',
    value: sortKeyEnum.FullName,
    isSortable: true,
  },
  {
    id: 2,
    name: 'Publish on',
    value: sortKeyEnum.StartDate,
    isSortable: true,
  },
  {
    id: 3,
    name: 'Message',
    value: sortKeyEnum.EndDate,
    isSortable: true,
  },
  {
    id: 4,
    name: 'Person',
    value: sortKeyEnum.Person,
    isSortable: true,
  },
  {
    id: 5,
    name: 'Controller',
    value: sortKeyEnum.Controller,
    isSortable: true,
  },
  {
    id: 0,
    name: '',
    value: sortKeyEnum.FullName,
    isSortable: false,
  },
];

const filters = { 
  'message': '',
  'reader_name': '',
  'person': '',
} as InstanceLogsFilterTableType;

const tableHeaderTemplateColumns = '15% 15% 30% 15% 15% 5%';

const InstanceLogsPage = () => {
  const dispatch = useDispatch();
  const [tableFilter, setTableFilter] = useState<InstanceLogsFilterTableType>(filters);

  const instanceLogs = useSelector((state: AppRootStateType) => state.instanceLogs.logs);

  const { isLoading, selectedFilterType, pagination } = useSelector((state: AppRootStateType) =>
    state.instanceLogs);

  const tableHeader = tableHeaderItems.map((item) => (
    <TableHeaderCeilTemplate key={item.id} item={item}  />
  ));
  

  function handleChangePagination(pagination: paginationType) {
    dispatch(setPaginationAC(pagination));
  }

  const filteredLogs = instanceLogs.filter(log => {
    return (Object.entries(tableFilter) as [keyof InstanceLogsFilterTableType, string][])
      .every(([key, value]) => {
        if (!value) return true;
        return log[key]?.includes(value);
      });
  });

  const arrowMode = selectedFilterType !== 'Live';

  const paginatedLogs = arrowMode ? filteredLogs : filteredLogs.slice(
    (pagination.page - 1) * pagination.limit,
    pagination.page * pagination.limit
  );

  const itemRow = paginatedLogs.map((item, index) => {
    return (
      <InstanceLogsItem
        key={`instance_logs_item_${index}`}
        item={item}
        tableHeaderTemplateColumns={tableHeaderTemplateColumns}
      />
    );
  });
  
  return (
    <>
      {selectedFilterType === 'Live' && <LogsWebSocket/>}
      <TableTemplate
        header={<InstanceFilter/>}
        subHeader={<InstanceSubHeader setTableFilter={setTableFilter} 
          tableFilter={tableFilter} 
          selectedFilterType={selectedFilterType}/>}
        tableHeader={tableHeader}
        tableHeaderTemplateColumns={tableHeaderTemplateColumns}
        isLoaded={isLoading}
        body={itemRow}
        alwaysShowFooter={true}
        footer={
          <Pagination pagination={pagination} setPagination={handleChangePagination}
            totalCount={instanceLogs.length} arrowsMode={arrowMode}/>
        }
      >
      </TableTemplate>
    </>
  );
};

export default InstanceLogsPage;