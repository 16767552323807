import { useEffect, useState } from 'react';

export function useIsNeedHeaderSpoiler(headerContentBlock: any, headerMainBlock: any) {
  const [isNeedSpoiler, setIsNeedSpoiler] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      setIsNeedSpoiler(
        headerContentBlock?.current?.getBoundingClientRect().height - 1 >
          headerMainBlock?.current?.getBoundingClientRect().height,
      );
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [isNeedSpoiler];
}
