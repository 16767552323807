import React from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';

interface MultiSelectorProps {
  id: string;
  label: string;
  labelId: string;
  multiSelectKey: string;
  multiSelectsList: string[];
  multiSelectsValuesList: string[];
  handleChangeSelectedValue: (multiSelectKey: string, multiSelectsValuesList: string[]) => void;
}

const MultiSelector: React.FC<MultiSelectorProps> = (props) => {
  const {
    id,
    label,
    labelId,
    multiSelectKey,
    multiSelectsList,
    multiSelectsValuesList,
    handleChangeSelectedValue,
  } = props;

  const onChange = (event: SelectChangeEvent<string[]>) => {
    handleChangeSelectedValue(
      multiSelectKey,
      typeof event.target.value === 'string' ? [event.target.value] : event.target.value,
    );
  };

  const clearFiler = () => {
    handleChangeSelectedValue(multiSelectKey, []);
  };

  const availableValueItems = multiSelectsList.sort().map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  return (
    <FormControl fullWidth sx={{ marginTop: '1vh', paddingRight: '2vh' }} size="small">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        size="small"
        labelId={labelId}
        label={label}
        id={id}
        multiple
        value={multiSelectsValuesList}
        onChange={onChange}
        IconComponent={() => null}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={clearFiler} edge="end">
              <BackspaceOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
      >
        {availableValueItems}
      </Select>
    </FormControl>
  );
};

export default MultiSelector;
