import React, { useEffect } from 'react';
import s from './BuildingInfo.module.css';
import { useDispatch } from 'react-redux';
import { weworkAPI } from '../../../api/api';
import CustomModal from '../../templates/CustomModal/CustomModal';
import { BuildingAttributesType, BuildingDataType } from '../../../types/buildingInfoTypes';
import { addNotificationAC } from '../../../store/appReducer';
import { notificationEnum } from '../../../types/appTypes';
import MoreInfo from '../UserLogs/MoreInfo/MoreInfo';
import { useCurrentIp } from '../../../store/selectors/useCurrentIp';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';

type propsType = {
  setShowBuildingInfo: (value: boolean) => void;
};

const BuildingInfo: React.FC<propsType> = (props) => {
  const { setShowBuildingInfo } = props;

  const dispatch = useDispatch();

  const currentIp = useCurrentIp();
  const currentInstanceData = useCurrentInstanceData();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null); ///TODO remove null

  const [info, setInfo] = React.useState<BuildingDataType | null>(null);
  const [keys, setKeys] = React.useState<Array<string>>([]);

  const BuildingRow = keys.map((i: string) => {
    const key = i.split('_').join(' ');
    const keyStr = `${key[0].toUpperCase() + key.slice(1)}`;
    return (
      <MoreInfo
        key={i}
        itemKey={keyStr}
        value={info?.attributes[i as keyof BuildingAttributesType]}
        splitSymbol={'_'}/>
    );
  });

  const getBuildingInfo = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      if (currentInstanceData) {
        const info = await weworkAPI.getBuildingInfo(
          currentIp,
          currentInstanceData?.instance_id,
          currentInstanceData.server_id,
          currentInstanceData.platform,
        );
        setInfo(info);
        const keys =
          Object.keys(info.attributes).length === 0
            ? ['Note']
            : Object.keys(info.attributes);
        setKeys(keys);
        dispatch(addNotificationAC(notificationEnum.success, 'Load Building info success'));
      }
    } catch (e: any) {
      setErrorMessage(e.response.data.detail);
      dispatch(addNotificationAC(notificationEnum.error, e.response.data.detail));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBuildingInfo();
  }, []);

  return (
    <CustomModal
      handleClose={setShowBuildingInfo}
      width={500}
      titleText="Building Information"
      isLoading={isLoading}
      isLineHovered={true}
    >
      {info ? BuildingRow : <div className={s.modalBodyAlert}>{errorMessage}</div>}
    </CustomModal>
  );
};

export default BuildingInfo;
