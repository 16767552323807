import React, { ChangeEvent } from 'react';
import { FormControl, TextField } from '@mui/material';
import { InstanceLogsFilterTableType } from '../../../types/instanceLogTypes';

type InstanceFilterTableProps = {
  setFilter: (filters: InstanceLogsFilterTableType) => void,
  filters: InstanceLogsFilterTableType
};


const InstanceFilterTable = ({ setFilter, filters }: InstanceFilterTableProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>, parameter: string) => {
    setFilter({ ...filters, [parameter]: event.target.value });
  };
  return <FormControl style={{  display: 'flex', gap: '0.5em' }}>
    <div style={{  display: 'flex', gap: '1em', justifyContent: 'space-between' }}>
      <TextField
        style={{ width: '100%' }}
        value={filters['reader_name']}
        type={'text'}
        size={'small'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'reader_name')}
        label="Enter reader"
      />
      <TextField
        style={{ width: '100%' }}
        value={filters['person']}
        type={'text'}
        size={'small'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'person')}
        label="Enter person"
      />
    </div>
    <TextField
      value={filters['message']}
      type={'text'}
      size={'small'}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'message')}
      label="Enter message"
    />
  </FormControl>;
}; 

export default InstanceFilterTable;