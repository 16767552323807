import React, { ReactNode } from 'react';
import s from './TableItemCeilTemplate.module.css';

type propsType = {
  isOverflowVisible?: boolean;
  children?: ReactNode;
};

const TableItemCeilTemplate: React.FC<propsType> = (props) => {
  const { isOverflowVisible } = props;

  return (
    <div
      className={`${s.tableItemCeil} text-2xs`}
      style={{ overflow: isOverflowVisible ? 'visible' : 'hidden' }}
    >
      {props.children}
    </div>
  );
};

export default TableItemCeilTemplate;
