import React, { useEffect } from 'react';
import cs from '../../../styles/commonStyles.module.css';
import { useDispatch } from 'react-redux';
import { weworkAPI } from '../../../api/api';
import CustomModal from '../../templates/CustomModal/CustomModal';
import { ServerCommonDataType } from '../../../types/buildingInfoTypes';
import { addNotificationAC } from '../../../store/appReducer';
import { notificationEnum } from '../../../types/appTypes';
import s from '../BuildingInfo/BuildingInfo.module.css';
import { useCurrentIp } from '../../../store/selectors/useCurrentIp';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';

type propsType = {
  setShowModalControllerInfo: (value: boolean) => void;
};

const initData: ServerCommonDataType = {
  server_id: 0,
  server_name: '',
};

const ServerInfo: React.FC<propsType> = (props) => {
  const { setShowModalControllerInfo } = props;

  const dispatch = useDispatch();

  const currentIp = useCurrentIp();
  const currentInstanceData = useCurrentInstanceData();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [info, setInfo] = React.useState<ServerCommonDataType>(initData);

  const getServerInfo = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      if (currentInstanceData) {
        const info = await weworkAPI.getServerInfo(
          currentIp,
          currentInstanceData?.instance_id,
          currentInstanceData?.server_id,
          currentInstanceData?.platform,
        );
        setInfo(info);
        dispatch(addNotificationAC(notificationEnum.success, 'Load Server info success'));
      }
    } catch (e: any) {
      setErrorMessage(e.response.data.detail);
      dispatch(addNotificationAC(notificationEnum.error, 'Load Server info failed'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getServerInfo();
  }, []);

  return (
    <CustomModal
      handleClose={setShowModalControllerInfo}
      titleText="Server Information"
      isLoading={isLoading}
      isLineHovered={true}
    >
      {errorMessage ? (
        <div className={s.modalBodyAlert}>{errorMessage}</div>
      ) : (
        <>
          <div className={cs.moreInfoLineForModal}>
            <p>Server id:</p>
            <p>{info.server_id}</p>
          </div>
          <div className={cs.moreInfoLineForModal}>
            <p>Server name:</p>
            <p>{info.server_name}</p>
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default ServerInfo;
