import React, { ReactNode } from 'react';
import s from './TableItemButtonTemplate.module.css';

type propsType = {
  minWidth?: number;
  children?: ReactNode;
};

const TableItemButtonTemplate: React.FC<propsType> = (props) => {
  const { minWidth, children } = props;

  return (
    <div className={s.ceilButton} style={{ minWidth: `${minWidth}px` }}>
      {children}
    </div>
  );
};

export default TableItemButtonTemplate;
