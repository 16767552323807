import React from 'react';
import s from './TableHeaderCeilTemplate.module.css';
import { Button } from '@wework/ray2';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import { headerTableType, sortKeyEnum } from '../../../../types/tableTemplateTypes';
import { sortInfoType } from '../../../../types/readersTypes';
import MultiSelector from '../../../modules/MultiSelector/MultiSelector';

type propType = {
  item: headerTableType;
  sortInfo?: sortInfoType;
  sortFunc?: (keySort: sortKeyEnum) => void;
  multiSelectKey?: string;
  multiSelectsList?: any; // TODO
  multiSelectsValuesList?: any; // TODO
  label?: string;
  handleChangeSelectedValue?: (multiSelectKey: string, multiSelectsValuesList: string[]) => void;
};

export const TableHeaderCeilTemplate: React.FC<propType> = (props) => {
  const {
    item,
    sortInfo,
    sortFunc,
    multiSelectKey,
    multiSelectsList,
    multiSelectsValuesList,
    label,
    handleChangeSelectedValue,
  } = props;

  const multiSelectItem = () => {
    const multiLabel = label || '';
    if (
      multiSelectsList &&
      multiSelectKey &&
      multiSelectsValuesList &&
      item.isMultiselect &&
      handleChangeSelectedValue
    ) {
      return (
        <MultiSelector
          id={multiLabel}
          label={multiLabel}
          labelId={multiLabel}
          multiSelectKey={multiSelectKey}
          multiSelectsList={multiSelectsList[multiSelectKey]}
          multiSelectsValuesList={multiSelectsValuesList[multiSelectKey]}
          handleChangeSelectedValue={handleChangeSelectedValue}
        />
      );
    }
    return null;
  };

  return (
    <div className={s.container}>
      {multiSelectItem()}
      <div className={s.ceilHeader}>
        <div className={s.ceilText}>{item.name}</div>

        {item.isSortable && sortInfo && (
          <Button
            className={s.sortButton}
            theme="outline"
            onClick={() => {
              sortFunc && item.value && sortFunc(item.value);
            }}
          >
            {sortInfo.keySort === item.value ? (
              sortInfo.directionSort === 1 ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )
            ) : (
              <RemoveIcon fontSize="small" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
