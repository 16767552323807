import React from 'react';
import cs from '../../../../styles/commonStyles.module.css';
import DetailsAccordion from '../DetailsAccordion/DetailsAccordion';

type propsType = {
  itemKey: string;
  value?: string | number;
  splitSymbol: string | RegExp;
  isDetailedInformation?: boolean ;
};

const MoreInfo: React.FC<propsType> = (props) => {
  const { itemKey, value, splitSymbol, isDetailedInformation } = props;

  return (
    (isDetailedInformation && typeof value === 'string') ?
      <DetailsAccordion itemKey={itemKey} splitSymbol={splitSymbol} value={value}/>
      :
      <div className={cs.moreInfoLineForModal}>
        <>
          <p className={cs.moreInfoLineForModal__Key}>{itemKey.split(splitSymbol).join(' ')}:</p>
          <p>{value}</p>
        </>
      </div>
  );
};

export default MoreInfo;
