import s from '../../pages/HolidayPage/HolidayPage.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePickerByRegularMode } from '../../../utils/TimePickerByRegularMode';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';
import { setDateFromAC, setDateToAC, setInitDateAC } from '../../../store/instanceLogsReducer';
import { utils_instance } from '../../../utils/Utils';


const InstanceFilterDatePicker = () => {
  const currentInstanceData = useCurrentInstanceData();

  const dispatch = useDispatch();
  const { dateFrom, dateTo } = useSelector(
    (state: AppRootStateType) => state.instanceLogs);

  const initData = () => {
    if (!currentInstanceData) return;

    const now = new Date(); 
    const time = new Date(now).setUTCHours(0, 0, 0, 0);

    const convertedStartDate = utils_instance.convertUTCToLocal(time);

    dispatch(setInitDateAC(convertedStartDate.getTime(),
      convertedStartDate.getTime() + 24 * 60 * 60 * 1000));
  };

  useEffect(() => {
    initData(); 
  }, [currentInstanceData]);
  
  const handleChangeStartTimeDate = (newValue: number | null) => {
    if (newValue === null) return;
    dispatch(setDateFromAC(newValue));
  };
  
  const handleChangeEndTimeDate = (newValue: number | null) => {
    if (newValue === null) return;
    dispatch(setDateToAC(newValue));
  };

  
  return <>
    <div className={s.date_wrapper}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePickerByRegularMode
          isRegularMode={false}
          isDatePicker={false}
          value={dateFrom}
          setValue={handleChangeStartTimeDate}
          minValue={null}
          disablePast={false}
          size='small'
          dataTimeLabel="Start time"
          withSeconds={true}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePickerByRegularMode
          isRegularMode={false}
          isDatePicker={false}
          disablePast={false}
          value={dateTo}
          setValue={handleChangeEndTimeDate}
          minValue={null}
          size='small'
          dataTimeLabel="End time"
          withSeconds={true}
        />
      </LocalizationProvider>
    </div>
  </>;
};

export default InstanceFilterDatePicker;