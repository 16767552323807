import React from 'react';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Button } from '@wework/ray2';
import { useIsNarrower } from '../../../utils/useIsNarrower';

const FeedbackBlock: React.FC = () => {
  const isMobile = useIsNarrower(920);

  const SendFeedbackClick = async () => {
    window.open('https://forms.gle/DtjLKgxNMNBZFAyo9');
  };

  return (
    <Button size="medium" theme="outline" onClick={SendFeedbackClick}>
      {!isMobile && 'Feedback '}
      <span className="icon-button">
        <FeedbackIcon fontSize={isMobile ? 'medium' : 'small'} />
      </span>
    </Button>
  );
};

export default FeedbackBlock;
