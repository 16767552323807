import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import s from './Profile.module.css';
import { Button } from '@wework/ray2';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import BuildingInfo from '../BuildingInfo/BuildingInfo';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import { RoleLabels } from '../../../types/types';
import ServerInfo from '../ServerInfo/ServerInfo';
import SelectInstance from '../SelectInstance/SelectInstance';
import { useIsNarrower } from '../../../utils/useIsNarrower';
import { InstancePublicDataType } from '../../../types/appTypes';
import { setTokenAC } from '../../../store/authReducer';
import config from '../../../config';
import { weworkAPI } from '../../../api/api';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';

const Profile = () => {
  const dispatch = useDispatch();

  const mainUserRole = useSelector<AppRootStateType, number>((state) => state.auth.mainUserRole);

  const currentInstanceData = useCurrentInstanceData();

  const { user, logout } = useAuth0();

  const isMobile = useIsNarrower(920);

  const [showModalDropMenu, setShowModalDropMenu] = useState<boolean>(false);

  const [showModalSelectInstance, setShowModalSelectInstance] = useState<boolean>(false);
  const [showModalSelectDefaultInstance, setShowModalSelectDefaultInstance] =
    useState<boolean>(false);

  const [showModalBuildingInfo, setShowModalBuildingInfo] = useState<boolean>(false);
  const [showModalControllerInfo, setShowModalControllerInfo] = useState<boolean>(false);

  const [savedDefaultInstance, setSavedDefaultInstance] = useState<InstancePublicDataType | null>(
    null,
  );

  const [backendVersion, setBackendVersion] = useState<string>('');

  const loadVersion = async () => {
    const version = await weworkAPI.getBackendVersion();
    setBackendVersion(version.version);
  };

  useEffect(() => {
    loadVersion();
  }, []);

  useEffect(() => {
    let savedInstanceData = {} as InstancePublicDataType;
    const savedInstance = localStorage.getItem('savedInstance');
    if (savedInstance) {
      savedInstanceData = {
        ...savedInstanceData,
        ...JSON.parse(savedInstance),
      };
      setSavedDefaultInstance(savedInstanceData);
    } else {
      setSavedDefaultInstance(null);
    }
  }, [showModalDropMenu]);

  const logoutHandler = () => {
    localStorage.removeItem('auth');
    dispatch(setTokenAC(null));
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const ChangeSelectInstanceClick = async () => {
    setShowModalSelectInstance(true);
    setShowModalDropMenu(false);
  };

  const ChangeSelectDefaultInstanceClick = async () => {
    setShowModalSelectDefaultInstance(true);
    setShowModalDropMenu(false);
  };

  const BuildingInfoClick = async () => {
    setShowModalBuildingInfo(true);
    setShowModalDropMenu(false);
  };

  const ControllerInfoClick = async () => {
    setShowModalControllerInfo(true);
    setShowModalDropMenu(false);
  };

  return (
    <div className={s.profile}>
      <Button
        size="medium"
        theme={isMobile ? 'outline' : 'text'}
        onClick={() => setShowModalDropMenu(!showModalDropMenu)}
      >
        {!isMobile && user?.nickname}
        <span className="icon-button">
          <PersonOutlinedIcon fontSize={isMobile ? 'medium' : 'small'} />
        </span>
      </Button>

      {showModalDropMenu && (
        <>
          <div
            className={s.backgroundModal}
            onClick={() => setShowModalDropMenu(!showModalDropMenu)}
          />
          <div className={`${s.bubble} ${isMobile && s.bubble_mobile}`}>
            <div className={s.bubbleItem}>
              <p className={s.bubbleItem__name}>Role: </p>
              <p className={s.bubbleItem__value}>{RoleLabels[Number(mainUserRole)]}</p>
            </div>

            {currentInstanceData ? (
              <>
                {isMobile && (
                  <div className={s.bubbleItem}>
                    <p className={s.bubbleItem__name}>Instance:</p>
                    <p className={s.bubbleItem__value}>{currentInstanceData?.instance_name}</p>
                  </div>
                )}

                <div className={s.bubbleItem}>
                  <p className={s.bubbleItem__name}>Server:</p>
                  <p className={s.bubbleItem__value}>{currentInstanceData?.server_name}</p>
                </div>

                <div className={s.bubbleItem}>
                  <p className={s.bubbleItem__name}>City:</p>
                  <p className={s.bubbleItem__value}>{currentInstanceData?.city}</p>
                </div>

                <div className={s.bubbleItem}>
                  <p className={s.bubbleItem__name}>Region:</p>
                  <p className={s.bubbleItem__value}>{currentInstanceData?.region}</p>
                </div>

                <div className={s.bubbleItem}>
                  <p className={s.bubbleItem__name}>Territory:</p>
                  <p className={s.bubbleItem__value}>{currentInstanceData?.territory}</p>
                </div>

                {savedDefaultInstance && (
                  <div className={s.bubbleItem}>
                    <p className={s.bubbleItem__name}>Default Instance:</p>
                    <p className={s.bubbleItem__value}>{savedDefaultInstance.instance_name}</p>
                  </div>
                )}

                {/*TODO {isAvailableSelectInstance &&*/}
                <>
                  <Button size="small" theme="outline" onClick={ChangeSelectDefaultInstanceClick}>
                    Select Default Instance
                  </Button>
                  <Button size="small" theme="outline" onClick={ChangeSelectInstanceClick}>
                    Change Instance
                  </Button>
                </>

                <Button size="small" theme="outline" onClick={BuildingInfoClick}>
                  Building Information
                </Button>

                <Button size="small" theme="outline" onClick={ControllerInfoClick}>
                  Server Information
                </Button>
              </>
            ) : (
              'isAvailableSelectInstance' && (
                <Button size="small" theme="outline" onClick={ChangeSelectInstanceClick}>
                  Select Instance
                </Button>
              )
            )}
            <Button size="small" theme="outline" onClick={logoutHandler}>
              Logout
            </Button>

            <span className={s.version}>version: F{config.version}-B{backendVersion}</span>
          </div>
        </>
      )}

      {showModalSelectInstance && (
        <SelectInstance setShowModalInstance={setShowModalSelectInstance} />
      )}
      {showModalSelectDefaultInstance && (
        <SelectInstance setShowModalInstance={setShowModalSelectDefaultInstance} selectToDefault />
      )}
      {showModalBuildingInfo && <BuildingInfo setShowBuildingInfo={setShowModalBuildingInfo} />}
      {showModalControllerInfo && (
        <ServerInfo setShowModalControllerInfo={setShowModalControllerInfo} />
      )}
    </div>
  );
};

export default Profile;
