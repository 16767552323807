import InstanceFilterDatePicker from './InstanceFilterDatePicker';
import InstanceFilterTable from './InstanceFilterTable';
import React from 'react';
import { InstanceLogsFilterTableType } from '../../../types/instanceLogTypes';

type InstanceSubHeaderPropsType = {
  setTableFilter: (filters: InstanceLogsFilterTableType) => void,
  tableFilter: InstanceLogsFilterTableType
  selectedFilterType: string
}

const InstanceSubHeader = ({ setTableFilter,
  tableFilter, selectedFilterType }: InstanceSubHeaderPropsType) => {

  return <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
    {selectedFilterType === 'Date' && <InstanceFilterDatePicker/>}
    <InstanceFilterTable setFilter={setTableFilter} filters={tableFilter}/>
  </div>;
};

export default InstanceSubHeader;