import React, { ReactNode } from 'react';
import s from './Footer.module.css';

type propsType = {
  children?: ReactNode;
};

const Footer: React.FC<propsType> = (props) => {
  return <div className={s.footerBody}>{props.children}</div>;
};

export default Footer;
