import React, { ReactNode } from 'react';
import s from './TableTemplate.module.css';
import Header from '../Header/Header';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../Footer/Footer';
import { Button } from '@wework/ray2';
import ReplayIcon from '@mui/icons-material/Replay';

type propsType = {
  header?: any;
  headerAlwaysVisible?: boolean;
  subHeader?: any;
  tableHeader: any;
  tableHeaderTemplateColumns: string;
  body: any;
  footer?: any;
  alwaysShowFooter?: boolean;
  isLoaded: boolean;
  children?: ReactNode;
  reloadFunc?: () => void;
};

const TableTemplate: React.FC<propsType> = (props) => {
  const {
    header,
    headerAlwaysVisible,
    subHeader,
    tableHeader,
    tableHeaderTemplateColumns,
    body,
    footer,
    isLoaded,
    children,
    reloadFunc,
    alwaysShowFooter = false,
  } = props;

  const noDataBlock = () => {
    return (
      <div className={`${s.tableContentCenter} ${s.tableContentColumn}`}>
        <p>No data</p>
        {reloadFunc && (
          <Button size="medium" theme="outline" onClick={reloadFunc}>
            {'Reload '}
            <span className="icon-button">
              <ReplayIcon fontSize="medium" />
            </span>
          </Button>
        )}
      </div>
    );
  };

  const headerBlock = () => {
    if (tableHeader === '') {
      return <></>;
    }
    return (
      <div className={s.tableHeader} style={{ gridTemplateColumns: tableHeaderTemplateColumns }}>
        {tableHeader}
      </div>
    );
  };

  return (
    <div className={s.tableContainer}>
      <Header header={header} subHeader={subHeader} />
      {children}
      {isLoaded ? (
        <div className={s.tableContentCenter}>
          <CircularProgress />
        </div>
      ) : <>
        <div className={s.tableContent}>
          <div className={`${s.tableBody} ${!footer && s.tableBodyWithFooter}`}>
            {headerAlwaysVisible ? headerBlock() : !!body.length && headerBlock()}
            {!body.length ? noDataBlock() : body}
          </div>
        </div>
        {(!!body.length || alwaysShowFooter) && footer && <Footer>{footer}</Footer>}
      </>}
    </div>
  );
};

export default TableTemplate;
