import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import cs from '../../../../styles/commonStyles.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { utils_instance } from '../../../../utils/Utils';

type propsType = {
  itemKey: string;
  value: string;
  splitSymbol: string | RegExp;
};

const StyledAccordionSummary = styled(AccordionSummary)({
  minHeight: 24,
  maxHeight: 24,
  backgroundColor: 'aliceblue',
  display: 'flex',
  paddingRight: 20,
  paddingLeft: 20,
  '&:hover': {
    backgroundColor: '#00000010'
  },
  '&.Mui-expanded': {
    minHeight: 24,
    backgroundColor: 'aliceblue',
    '&:hover': {
      backgroundColor: '#00000010'
    },
  },
});

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  backgroundColor: 'aliceblue',
  paddingX: 20,
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const DetailsAccordion: React.FC<propsType> = (props) => {
  const { itemKey, value, splitSymbol } = props;

  const deviceStatusArray: [string, string][] = utils_instance.parseDetailString(value);
  const detailRow = deviceStatusArray.map(([device, status], index) => {
    return (
      <div className={cs.DetailsLine} key={index}>
        <p className={cs.Device}>{device}: </p>
        <p className={cs.Status}>{status}</p>
      </div>
    );
  });

  return (
    <div className={cs.AccordionItem}>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <p className={cs.moreInfoLineForModal__Key}>
            {itemKey.split(splitSymbol).join(' ')}:
          </p>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            {detailRow}
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default DetailsAccordion;
