import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewLogAC } from '../store/instanceLogsReducer';
import { AppRootStateType } from '../store/store';
import { useCurrentInstanceData } from '../store/selectors/useCurrentInstanceData';
import { clearLogsSocketObjectAC, setLogsSocketObjectAC } from '../store/appReducer';

const LogsWebSocket = () => {
  const dispatch = useDispatch();
  const token = useSelector<AppRootStateType, string | null>((state) => state.auth.token);
  const currentInstanceData = useCurrentInstanceData();
  const logsSocket = useSelector<AppRootStateType, WebSocket | undefined>(
    (state) => state.app.logsSocket);
  useEffect(() => {
    if(logsSocket) {
      logsSocket.onmessage = (event) => {
        const log = JSON.parse(event.data);
        dispatch(addNewLogAC(log));
      };

      logsSocket.onclose = () => {
        dispatch(clearLogsSocketObjectAC());
      };
    } else {
      if (token && currentInstanceData) {
        dispatch(setLogsSocketObjectAC(token,
          currentInstanceData.instance_id,
          currentInstanceData.server_id,
          currentInstanceData.platform, ''));
      }
    }
  }, [currentInstanceData, logsSocket, token]);

  useEffect(() => {
    return () => {
      dispatch(clearLogsSocketObjectAC());
    };
  }, []);

  return null;
};

export default LogsWebSocket;
